import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_TRAINING_PLANS } from 'Consts/routes';
import { 
    API_RESOURCE_USERS, 
} from 'Consts/apiResources';
import {
    USER_ROLE_ADEPT,
} from 'Consts/userRoles';
import {
    SURVEY_QUESTION_GENDER,
    SURVEY_QUESTION_AGE,
    SURVEY_QUESTION_HEIGHT,
    SURVEY_QUESTION_WEIGHT,
    SURVEY_QUESTION_EXPERIENCE,
    SURVEY_QUESTION_TARGET,
    SURVEY_QUESTION_AVAILABLE_TIME,
    SURVEY_QUESTION_LOCATION,
    SURVEY_QUESTION_DESEASES,
    SURVEY_QUESTION_ACTIVITY,

    SURVEY_QUESTION_GENDER_OPTIONS,
    SURVEY_QUESTION_AGE_OPTIONS,
    SURVEY_QUESTION_EXPERIENCE_OPTIONS,
    SURVEY_QUESTION_TARGET_OPTIONS,
    SURVEY_QUESTIONS_AVAILABLE_TIME_OPTIONS,
    SURVEY_QUESTION_LOCATION_OPTIONS,
    SURVEY_QUESTION_ACTIVITY_OPTIONS,
} from 'Consts/tpSurvey';

import { fromSelectObject } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';
import { getBmiProfile } from 'Utils/tpProfile';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import { TAB_SURVEY } from 'Components/pages/admin/TrainingPlans/component';

export default class AdminTrainingPlanSurveyEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data || {}),
                },
            }));
        }
    }

    getQuestionsAnswers = questions => {
        let result = {};

        if (questions) {
            questions.forEach(question => {
                result = {
                    ...result,
                    [question.key]: question.answerKey,
                }
            });
        }

        return result;
    }

    dataToFormState = data => {
        return {
            ...data,
            userId: data.user
                ? { value: data.user.id, label: getFullName(data.user).label }
                : null,
            ...this.getQuestionsAnswers(data?.questions),
        };
    }

    formStateToData = formState => {
        return {
            userId: fromSelectObject(formState.userId),
            questions: [
                {
                    'key': SURVEY_QUESTION_GENDER,
                    'answerKey': fromSelectObject(formState.gender),
                    'answer': formState.gender.label,
                },
                {
                    'key': SURVEY_QUESTION_AGE,
                    'answerKey': fromSelectObject(formState.age),
                    'answer': formState.age.label,
                },
                {
                    'key': SURVEY_QUESTION_HEIGHT,
                    'answerKey': formState.height,
                    'answer': formState.height,
                },
                {
                    'key': SURVEY_QUESTION_WEIGHT,
                    'answerKey': formState.weight,
                    'answer': formState.weight,
                },
                {
                    'key': SURVEY_QUESTION_EXPERIENCE,
                    'answerKey': fromSelectObject(formState.experience),
                    'answer': formState.experience.label,
                },
                {
                    'key': SURVEY_QUESTION_TARGET,
                    'answerKey': fromSelectObject(formState.target),
                    'answer': formState.target.label,
                },
                {
                    'key': SURVEY_QUESTION_AVAILABLE_TIME,
                    'answerKey': fromSelectObject(formState.availableTime),
                    'answer': formState.availableTime.label,
                },
                {
                    'key': SURVEY_QUESTION_LOCATION,
                    'answerKey': fromSelectObject(formState.location),
                    'answer': formState.location.label,
                },
                {
                    'key': SURVEY_QUESTION_DESEASES,
                    'answerKey': formState.deseases,
                    'answer': formState.deseases,
                },
                {
                    'key': SURVEY_QUESTION_ACTIVITY,
                    'answerKey': fromSelectObject(formState.activity),
                    'profileName': formState.activity.profileName,
                    'answer': formState.activity.label,
                },
            ]
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        return actions.update({
            id: data.id,
            ...this.formStateToData(formState),
        });
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...this.formStateToData(formState),
        }).then(() => {
            history.push(
                withVariables(
                    ADMIN_TRAINING_PLANS.path, 
                    { }, 
                    { tab: TAB_SURVEY }
                )
            );
        });
    }

    render() {
        const { location, history, actions, data } = this.props;
        const { formState } = this.state;

        const bmi = Number((Number(formState.weight) / ((Number(formState.height / 100) * (Number(formState.height / 100))))).toFixed(2));

        const availableTargets = SURVEY_QUESTION_TARGET_OPTIONS.filter(option => {
            return bmi >= option.bmiMin && bmi < option.bmiMax
        });

        return (
            <StyledComponent
                className="admin-trainig-plan-survey-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'select',
                            name: 'userId',
                            label: 'Adept',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    role: USER_ROLE_ADEPT
                                }),
                                onChange: value => this.setState(prevState => ({
                                    formState: {
                                        ...prevState.formState,
                                        userId: fromSelectObject(value)?.value,
                                    },
                                })),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getFullName(element).label,
                                }),
                            },
                            required: true,
                        }, {
                            type: 'select',
                            name: 'gender',
                            label: 'Płeć',
                            required: true,
                            options: SURVEY_QUESTION_GENDER_OPTIONS.map(option => ({
                                value: option.key,
                                label: option.label,
                            })),
                        }, {
                            type: 'select',
                            name: 'age',
                            label: 'Ile masz lat?',
                            required: true,
                            options: SURVEY_QUESTION_AGE_OPTIONS.map(option => ({
                                value: option.key,
                                label: option.label,
                            })),
                        }, {
                            type: 'input',
                            name: 'height',
                            label: 'Wzrost w cm',
                            required: true,
                            inputProps: {
                                type: 'number',
                                styleVersion: 2,
                                placeholder: '...',
                            },
                        }, {
                            type: 'input',
                            name: 'weight',
                            label: 'Waga w kg',
                            required: true,
                            inputProps: {
                                type: 'number',
                                styleVersion: 2,
                                placeholder: '...',
                            },
                        }, {
                            type: 'element',
                            key: 'bmi',
                            isVisible: !isNaN(bmi),
                            children: (
                                <>
                                    <p>{"BMI: " + bmi}</p>
                                    <p>{getBmiProfile(bmi)}</p>
                                </>
                            )
                        }, {
                            type: 'select',
                            name: 'experience',
                            label: 'Doświadczenie treningowe',
                            required: true,
                            options: SURVEY_QUESTION_EXPERIENCE_OPTIONS.map(option => ({
                                value: option.key,
                                label: option.label,
                            })),
                        }, {
                            type: 'select',
                            name: 'target',
                            label: 'Cel treningowy',
                            isVisible: !isNaN(bmi),
                            required: true,
                            options: availableTargets.map(option => ({
                                value: option.key,
                                label: option.label,
                            })),
                        }, {
                            type: 'select',
                            name: 'availableTime',
                            label: 'Czas jaki masz na trening',
                            required: true,
                            options: SURVEY_QUESTIONS_AVAILABLE_TIME_OPTIONS.map(option => ({
                                value: option.key,
                                label: option.label,
                            })),
                        }, {
                            type: 'select',
                            name: 'location',
                            label: 'Miejsce treningów',
                            required: true,
                            options: SURVEY_QUESTION_LOCATION_OPTIONS.map(option => ({
                                value: option.key,
                                label: option.label,
                            })),
                        }, {
                            type: 'select',
                            name: 'activity',
                            label: 'Aktywność',
                            required: true,
                            options: SURVEY_QUESTION_ACTIVITY_OPTIONS.map(option => ({
                                value: option.key,
                                label: option.label,
                                profileName: option.profileName,
                            })),
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}