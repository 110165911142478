export const SURVEY_QUESTION_GENDER = 'gender';
export const SURVEY_QUESTION_AGE = 'age';
export const SURVEY_QUESTION_HEIGHT = 'height';
export const SURVEY_QUESTION_WEIGHT = 'weight';
export const SURVEY_QUESTION_EXPERIENCE = 'experience';
export const SURVEY_QUESTION_TARGET = 'target';
export const SURVEY_QUESTION_AVAILABLE_TIME = 'availableTime';
export const SURVEY_QUESTION_LOCATION = 'location';
export const SURVEY_QUESTION_DESEASES = 'deseases';
export const SURVEY_QUESTION_ACTIVITY = 'activity';

export const SURVEY_QUESTION_GENDER_OPTION_MALE = 'male';
export const SURVEY_QUESTION_GENDER_OPTION_FEMALE = 'female';
export const SURVEY_QUESTION_GENDER_OPTIONS = [
    {
        key: SURVEY_QUESTION_GENDER_OPTION_MALE,
        label: 'Mężczyzna',
    },
    {
        key: SURVEY_QUESTION_GENDER_OPTION_FEMALE,
        label: 'Kobieta',
    },
];

export const SURVEY_QUESTION_AGE_OPTION_YOUNG = 'young';
export const SURVEY_QUESTION_AGE_OPTION_MEDIUM = 'medium';
export const SURVEY_QUESTION_AGE_OPTION_OLD = 'old';
export const SURVEY_QUESTION_AGE_OPTIONS = [
    {
        key: SURVEY_QUESTION_AGE_OPTION_YOUNG,
        label: 'Młoda (16-30)',
    },
    {
        key: SURVEY_QUESTION_AGE_OPTION_MEDIUM,
        label: 'Średnia (31-50)',
    },
    {
        key: SURVEY_QUESTION_AGE_OPTION_OLD,
        label: 'Starsza (50+)',
    },
];

export const SURVEY_QUESTION_EXPERIENCE_LOW = 'low';
export const SURVEY_QUESTION_EXPERIENCE_MEDIUM = 'medium';
export const SURVEY_QUESTION_EXPERIENCE_HIGH = 'high';
export const SURVEY_QUESTION_EXPERIENCE_OPTIONS = [
    {
        key: SURVEY_QUESTION_EXPERIENCE_LOW,
        label: 'Niskie',
    },
    {
        key: SURVEY_QUESTION_EXPERIENCE_MEDIUM,
        label: 'Średnie',
    },
    {
        key: SURVEY_QUESTION_EXPERIENCE_HIGH,
        label: 'Zaawansowane',
    },
];

export const SURVEY_QUESTION_TARGET_WEIGHT_LOSS = 'weight_loss';
export const SURVEY_QUESTION_TARGET_MUSCLE_MASS_BUILDING = 'muscle_mass_building';
export const SURVEY_QUESTION_TARGET_FITNESS_IMPROVEMENT = 'fitness_improvement';
export const SURVEY_QUESTION_TARGET_OPTIONS = [
    {
        key: SURVEY_QUESTION_TARGET_WEIGHT_LOSS,
        label: 'Odchudzanie',
        bmiMin: 25,
        bmiMax: 999,
    },
    {
        key: SURVEY_QUESTION_TARGET_MUSCLE_MASS_BUILDING,
        label: 'Budowa masy mięśniowej',
        bmiMin: 0,
        bmiMax: 999,
    },
    {
        key: SURVEY_QUESTION_TARGET_FITNESS_IMPROVEMENT,
        label: 'Poprawa kondycji',
        bmiMin: 0,
        bmiMax: 25,
    },
];

export const SURVEY_QUESTIONS_AVAILABLE_TIME_ONE = 'one';
export const SURVEY_QUESTIONS_AVAILABLE_TIME_TWO = 'two';
export const SURVEY_QUESTIONS_AVAILABLE_TIME_THREE = 'three';
export const SURVEY_QUESTIONS_AVAILABLE_TIME_FOUR = 'four';
export const SURVEY_QUESTIONS_AVAILABLE_TIME_FIVE = 'five';
export const SURVEY_QUESTIONS_AVAILABLE_TIME_SIX = 'six';
export const SURVEY_QUESTIONS_AVAILABLE_TIME_OPTIONS = [
    {
        key: SURVEY_QUESTIONS_AVAILABLE_TIME_ONE,
        label: '1',
    },
    {
        key: SURVEY_QUESTIONS_AVAILABLE_TIME_TWO,
        label: '2',
    },
    {
        key: SURVEY_QUESTIONS_AVAILABLE_TIME_THREE,
        label: '3',
    },
    {
        key: SURVEY_QUESTIONS_AVAILABLE_TIME_FOUR,
        label: '4',
    },
    {
        key: SURVEY_QUESTIONS_AVAILABLE_TIME_FIVE,
        label: '5',
    },
    {
        key: SURVEY_QUESTIONS_AVAILABLE_TIME_SIX,
        label: '6',
    },
];

export const SURVEY_QUESTION_LOCATION_HOME = 'home';
export const SURVEY_QUESTION_LOCATION_GYM = 'gym';
export const SURVEY_QUESTION_LOCATION_OUTDOOR = 'outdoor';
export const SURVEY_QUESTION_LOCATION_OPTIONS = [
    {
        key: SURVEY_QUESTION_LOCATION_HOME,
        label: 'Dom',
    },
    {
        key: SURVEY_QUESTION_LOCATION_GYM,
        label: 'Siłownia',
    },
    {
        key: SURVEY_QUESTION_LOCATION_OUTDOOR,
        label: 'Outdoor',
    },
];

export const SURVEY_QUESTION_ACTIVITY_NONE = 'none';
export const SURVEY_QUESTION_ACTIVITY_OLD = 'old';
export const SURVEY_QUESTION_ACTIVITY_ACTIVE = 'active';
export const SURVEY_QUESTION_ACTIVITY_OPTIONS = [
    {
        key: SURVEY_QUESTION_ACTIVITY_NONE,
        profileName: 'Nieaktywny',
        label: 'Nigdy',
    },
    {
        key: SURVEY_QUESTION_ACTIVITY_OLD,
        profileName: 'Historycznie aktywny',
        label: '6 miesięcy temu lub mniej',
    },
    {
        key: SURVEY_QUESTION_ACTIVITY_ACTIVE,
        profileName: 'Aktywny',
        label: 'Aktywna/ny',
    }, 
];